<template>
  <div class="row">
    <input-select
      class="col-12"
      ref="form.cursoId"
      :options="opcoes.contas"
      v-model="form.contaId"
      :label="$t('FINANCEIRO.SELECIONAR_CONTA')"
      required
      valueField="id"
      textField="descricaoConta"
    />
    <div class="col-12 col-md-4">
      <input-date-only-btn
        ref="dataPagamento"
        v-model="form.dataPagamento"
        label="FINANCEIRO.DATA_PAGAMENTO"
        required
      />
    </div>
    <input-text
      class="col-12 col-md-4"
      label="Data Lançamento:"
      v-model="dataLancamento"
      disabled
    >
    </input-text>
    <input-text
      class="col-12 col-md-4"
      label="Data Vencimento:"
      v-model="dataVencimento"
      disabled
    >
    </input-text>

    <div class="col-12 col-md-3">
      <input-number
        ref="valor"
        v-model="form.valor"
        :min="0"
        label="FINANCEIRO.VALOR"
        decimal
        disabled
      />
    </div>
    <div class="col-12 col-md-3">
      <input-number
        ref="juros"
        v-model="form.juros"
        :min="0"
        label="Juros (R$)"
        decimal
        @change="editaJurosAtualizaValorTotal(form, $event)"
      />
    </div>
    <div class="col-12 col-md-3">
      <input-number
        ref="desconto"
        v-model="form.desconto"
        :min="0"
        label="Desconto (R$)"
        decimal
        @change="editaDescontoAtualizaValorTotal(form, $event)"
      />
    </div>
    <div class="col-12 col-md-3">
      <input-number
        ref="total"
        v-model="form.total"
        :min="0"
        label="Total"
        decimal
        disabled
      />
    </div>
  </div>
</template>
<script>
// Components:
import {
  InputNumber,
  InputDateOnlyBtn,
  InputText,
  InputSelect,
} from '@/components/inputs';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import ContaService from '@/common/services/conta/conta.service';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputNumber,
    InputDateOnlyBtn,
    InputText,
    InputSelect,
    // SelecionarConta,
  },
  data() {
    return {
      refs: [],
      opcoes: {
        contas: [],
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 100,
        total: 0,
      },
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getContas();
  },
  computed: {
    dataLancamento() {
      return helpers.formatarDataBr(this.form.dataLancamento);
    },
    dataVencimento() {
      return helpers.formatarDataBr(this.form.dataVencimento);
    },
  },
  methods: {
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodas(this.paginacao)
        .then(({ data }) => {
          this.opcoes.contas = data.itens;

          this.opcoes.contas.forEach((item) => {
            item.descricaoConta = `${item.bancoDescricao} | ag: ${item.agencia} | nº:${item.numeroConta} | ${item.tipoContaDescricao} | Fechamento: ${item.periodoFechamento} | Descrição: ${item.descricao} `;
          });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editaDescontoAtualizaValorTotal(item, valor_input) {
      this.$refs['desconto'].$el.style.borderBottom = '';
      item.desconto = parseFloat(valor_input);
      if (valor_input == '') {
        item.desconto = 0;
      }
      item.total =
        parseFloat(item.valor) +
        parseFloat(item.juros) -
        parseFloat(item.desconto);

      var totalParcial = parseFloat(item.valor) + parseFloat(item.juros);
      if (valor_input > totalParcial) {
        this.$refs['desconto'].$el.style.borderBottom = '1px solid red';
        return mensagem.warning(
          this.$t('Aviso'),
          this.$t(
            "O valor preenchido em 'Desconto' é maior que a soma entre Valor do documento e Juros"
          )
        );
      }
    },
    editaJurosAtualizaValorTotal(item, valor_input) {
      this.$refs['desconto'].$el.style.borderBottom = '';
      item.juros = parseFloat(valor_input);
      if (valor_input == '') {
        item.juros = 0;
      }

      item.total =
        parseFloat(item.valor) +
        parseFloat(item.juros) -
        parseFloat(item.desconto);
      var totalParcial = parseFloat(item.valor) + parseFloat(item.juros);
      if (item.desconto > totalParcial) {
        this.$refs['desconto'].$el.style.borderBottom = '1px solid red';

        return mensagem.erro(
          this.$t('Aviso'),
          this.$t(
            "O valor de 'Desconto' é maior que a soma entre Valor do documento e Juros"
          )
        );
      }
    },
  },
};
</script>
