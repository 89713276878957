<template>
  <div class="col-12 col-md-4">
    <div class="row">
      <div class="col-12">
        <slot name="checkbox"> </slot>
        <hr />
      </div>
      <b-col md=auto>
        <input-date-only-btn
          ref="dataPagamentoInicio"
          v-model="form.dataPagamentoInicio"
          label="FINANCEIRO_CONTAS_PAGAR.DATA_INICIAL"
          placeholder="FINANCEIRO_CONTAS_PAGAR.DATA_PLACEHOLDER"
          :disabled="!exibir"
        />
      </b-col>
      <b-col md=auto>
        <input-date-only-btn
          ref="dataPagamentoAte"
          v-model="form.dataPagamentoAte"
          label="FINANCEIRO_CONTAS_PAGAR.DATA_FINAL"
          placeholder="FINANCEIRO_CONTAS_PAGAR.DATA_PLACEHOLDER"
          :disabled="!exibir"
        />
      </b-col>
    </div>
  </div>
</template>
<script>
// Components:
import { InputDateOnlyBtn } from '@/components/inputs';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: { InputDateOnlyBtn },
};
</script>
