<template>
  <div class="row">
    <!-- valores: valor, total e quantidade de parcelas -->
    <div class="col-12 col-md-4">
      <input-number
        ref="valor"
        v-model="form.valor"
        :min="1"
        :label="$t('FINANCEIRO.VALOR_PARCELA')"
        decimal
        required
      />
    </div>
    <div class="col-12 col-md-4">
      <input-number
        ref="quantidadeParcelas"
        v-model="form.quantidadeParcelas"
        :min="0"
        :label="$t('FINANCEIRO.QUANTIDADE_PARCELAS')"
        required
      />
    </div>

    <!-- datas: lançamento, vencimento e pagamento -->
    <div class="col-12 col-md-4">
      <input-date-only-btn
        ref="dataLancamento"
        v-model="form.dataLancamento"
        label="FINANCEIRO.DATA_LANCAMENTO"
        required
      />
    </div>
    <div class="col-12 col-md-4">
      <input-date-only-btn
        ref="dataVencimento"
        v-model="form.dataVencimento"
        label="FINANCEIRO.DATA_VENCIMENTO"
        required
      />
    </div>

    <!-- outras informações: statusPagamento -->
    <!-- <div class="col-12 col-md-4">
      <input-select-multiple
        ref="statusPagamentoId"
        v-model="form.statusPagamentoId"
        :label="$t('FINANCEIRO_CONTAS_PAGAR.STATUS')"
        placeholder="FINANCEIRO_CONTAS_PAGAR.STATUS_PLACEHOLDER"
        :options="opcoes.status"
      />
    </div>

    <div class="col-12 col-md-4">
      <input-select-multiple
        ref="contaId"
        v-model="form.contaId"
        :label="$t('FINANCEIRO.CONTA')"
        :options="opcoes.contas"
      />
    </div> -->
    <!-- required -->

    <div class="col-12 col-md-4">
      <input-select-multiple
        ref="fornecedorId"
        v-model="form.fornecedorId"
        :label="$t('Fornecedores')"
        placeholder="Fornecedores"
        :options="opcoes.fornecedores"
        required
      />
    </div>

    <div class="col-12 col-md-4">
      <input-select-multiple
        ref="historicoLancamentoId"
        v-model="form.historicoLancamentoId"
        :label="$t('Histórico lançamento')"
        placeholder="Histórico lançamento"
        :options="opcoes.historicos"
        required
      />
    </div>
    <div class="col-12 mb-2 mt-4">
      <b-button
        variant="primary"
        @click="gerarLancamentos"
        :disabled="
          !(form.valor && form.quantidadeParcelas && form.dataVencimento)
        "
      >
        {{ $t('FINANCEIRO.GERAR_LANCAMENTOS') }}
      </b-button>
    </div>
    <b-table
      class=""
      :items="itens"
      :fields="fields"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="sm"
      show-empty
      responsive
      striped
      hover
      small
    >
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import ContaService from '@/common/services/conta/conta.service';
import EmpresaService from '@/common/services/empresa/empresa.service.js';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

// Components:
import {
  InputNumber,
  InputDateOnlyBtn,
  InputSelectMultiple,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputNumber,
    InputSelectMultiple,
  },
  mounted() {
    this.$emit('refs', this.$refs);

    this.getStatus();
    this.getContas();
    this.getFornecedores();
    this.getHistoricoLancamento();
  },
  data() {
    return {
      itens: [],
      fields: [
        {
          key: 'dataLancamento',
          label: this.$t('GERAL.DATA_LANCAMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'dataVencimento',
          label: this.$t('GERAL.DATA_VENCIMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'valor',
          label: this.$t('GERAL.VALOR_BRL'),
          formatter: (value) => {
            return value ? helpers.formatacaoMoedaBRL(value) : '-';
          },
          sortable: true,
        },
      ],
      opcoes: {
        contas: [],
        historicos: [],
        status: [],
        fornecedores: [],
      },
    };
  },
  watch: {
    'form.valor'(value, oldValue) {
      if (value !== oldValue) {
        this.limpaListagemLancamentos();
      }
    },
    'form.quantidadeParcelas'(value, oldValue) {
      if (value !== oldValue) {
        this.limpaListagemLancamentos();
      }
    },
    'form.dataVencimento'(value, oldValue) {
      if (value !== oldValue) {
        this.limpaListagemLancamentos();
      }
    },
  },
  methods: {
    gerarLancamentos() {
      if (
        this.form.valor &&
        this.form.quantidadeParcelas &&
        this.form.dataVencimento
      ) {
        this.limpaListagemLancamentos();
        const dataCalculoParcelas = this.form.dataVencimento.split('-');
        const [ano, mes, dia] = dataCalculoParcelas.map(Number);

        for (let i = 0; i < this.form.quantidadeParcelas; i++) {
          let correcaoMesInicial = 1;
          let dataParcela = new Date(ano, mes + i - correcaoMesInicial, dia);

          let verificaDiaSemana = dataParcela.getDay();
          if (verificaDiaSemana == 6) {
            dataParcela.setDate(dataParcela.getDate() + 2);
          }
          if (verificaDiaSemana == 0) {
            dataParcela.setDate(dataParcela.getDate() + 1);
          }

          let dataParcelaString = helpers.formatarDataUtc(dataParcela, false);
          this.itens.push({
            dataLancamento: this.form.dataVencimento,
            dataVencimento: dataParcelaString,
            valor: this.form.valor,
          });
        }
      }
    },
    limpaListagemLancamentos() {
      this.itens = [];
    },
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodasSemPaginacao()
        .then(({ data }) => {
          this.opcoes.contas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getFornecedores() {
      this.$store.dispatch(START_LOADING);
      EmpresaService.obterTodosFornecedoresSemPaginacao()
        .then(({ data }) => {
          data = data.map((row) => ({
            text: row.razaoSocial,
            value: row.id,
          }));
          this.opcoes.fornecedores = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getHistoricoLancamento() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterTodosHistoricosLancamentosSemPaginacao()
        .then(({ data }) => {
          this.opcoes.historicos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getStatus() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-pagamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.status = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
