<template>
  <modal
    id="modalContasPagar"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <form-contas-pagar :form="form" @refs="capturarRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import TituloService from '@/common/services/titulo/titulo.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import FormContasPagar from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormContasPagar,
  },
  data() {
    return {
      refs: {},
      filtroRepasse: [
        'codigo',
        'fornecedorId',
        'documento',
        'statusPagamentoId',
        'contaId',
        'historicoLancamentoId',
      ],
    };
  },
  computed: {
    titulo() {
      return !this.form.id
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
  },
  methods: {
    // FUNÇÕES AUXILIARES P/ VALIDAÇÃO DO FORMULÁRIO:
    validarFormulario() {
      if (this.form.valor < 0 || this.form.quantidadeParcelas < 0) {
        mensagem.warning(
          this.$t('GERAL.ATENCAO'),
          this.$t('FINANCEIRO_LANCAMENTOS.VALOR_NEGATIVO')
        );
        return;
      }
      if (this.form.dataVencimento < this.form.dataLancamento) {
        mensagem.warning(
          this.$t('GERAL.ATENCAO'),
          this.$t('FINANCEIRO_LANCAMENTOS.DATA_INVALIDA')
        );
        return;
      }
      return helpers.validarFormulario(this.refs);
    },
    capturarRefs(refs) {
      let temp = Object.assign(this.refs, refs);
      this.refs = temp;
    },
    obterSelecionado(tipo, item) {
      this.form[`${tipo}Id`] = item.id;
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.criarObjetoParaFormulario();
      TituloService.criarContaPagar(form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualiza-listagem');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    criarObjetoParaFormulario() {
      return this.filtroRepasse.reduce((acc, string) => {
        acc[string] = this.form[string]?.value;
        return { ...this.form, ...acc };
      }, {});
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'contasPagar');
    },
  },
};
</script>
