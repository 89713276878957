<template>
    <div class="row">
      <div class="col-12 mt-3">
        <input-date-only-btn
          ref="dataVencimento"
          v-model="form.dataVencimento"
          label="FINANCEIRO.NOVA_DATA_VENCIMENTO"
          required
        />
      </div>
    </div>
  </template>
  <script>
  // Components:
  
  import { InputDateOnlyBtn } from '@/components/inputs';
  //import mensagem from '@/common/utils/mensagem';
  
  export default {
    props: {
      form: { type: Object, default: Object },
    },
    components: {   
      InputDateOnlyBtn,
    },
    mounted() {
      this.$emit('refs', this.$refs);
    },
    methods: {
      
    },
  };
  </script>
  