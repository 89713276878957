<template>
  <div>
    <titulo-contas-pagar @cadastrar="openModal" />

    <filtro
      class="mb-3"
      :filtroAntigo="filtroAntigo"
      @filtrar="parametroFiltro"
      @limpar="listarContasPagar"
      @gerar-contas="gerarContas"
      @gerar-extrato="gerarExtrato"
      @baixar-titulo="redirecionaBaixarTitulo"
      :disabled="items.length === 0"
    />

    <tabela-contas-pagar
      :items="items"
      @atualiza-tabela="listarContasPagar"
      @baixa-titulo="redirecionaBaixarTitulo"
      @ajustar-titulo="openModal"
    />

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="listarContasPagar"
    />

    <modal-contas-pagar
      :exibir="modais.contasPagar"
      :form="modais.contasPagarDados"
      @fechar="closeModal('contasPagar')"
      @atualiza-listagem="listarContasPagar"
    />

    <modal-baixa-titulo
      :exibir="modais.baixaTitulo"
      :form="modais.baixaTituloDados"
      @atualiza-tabela="listarContasPagar"
      @fechar="closeModal('baixaTitulo')"
    />

    <modal-ajustar-titulo
      :exibir="modais.ajustarTitulo"
      :form="modais.ajustarTituloDados"
      @atualiza-tabela="listarContasPagar"
      @fechar="closeModal('ajustarTitulo')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import pdf from '@/common/utils/pdf';

// Services:
import TituloService from '@/common/services/titulo/titulo.service';

// Components:
import Filtro from './components/filtro/Index';
import TabelaContasPagar from './components/Tabela';
import Paginacao from '@/components/paginacao/Paginacao';
import {
  ModalBaixaTitulo,
  ModalContasPagar,
  ModalAjustarTitulo,
} from './components/modais/index';
import TituloContasPagar from '@/views/financeiro/lancamentos/contas-pagar/components/TituloContasPagar.vue';

export default {
  components: {
    TituloContasPagar,
    Filtro,
    TabelaContasPagar,
    Paginacao,
    ModalBaixaTitulo,
    ModalContasPagar,
    ModalAjustarTitulo,
  },
  data() {
    return {
      items: [],
      filtroSelecionado: {},
      filtroAntigo: {},
      modais: {
        contasPagar: false,
        contasPagarDados: {},
        baixaTitulo: false,
        baixaTituloDados: {},
        ajustarTitulo: false,
        ajustarTituloDados: {},
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  mounted() {
    if (localStorage.getItem('filtroContasPagar')) {
      this.parametroFiltro(
        JSON.parse(localStorage.getItem('filtroContasPagar'))
      );
      this.filtroAntigo = JSON.parse(localStorage.getItem('filtroContasPagar'));
    }
  },
  methods: {
    redirecionaBaixarTitulo(id) {
      this.$router.push({
        name: 'financeiro-contas-pagar-baixar-titulo',
        params: { id },
      });

      localStorage.setItem(
        'filtroContasPagar',
        JSON.stringify(this.filtroSelecionado)
      );
    },
    // FUNÇÕES FILTRO:
    parametroFiltro(filtro) {
      this.filtroSelecionado = filtro;
      this.listarContasPagar();
    },

    listarContasPagar() {
      this.$store.dispatch(START_LOADING);
      TituloService.filtrarContaPagar(this.filtroSelecionado, this.paginacao)
        .then((data) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES TABELA:
    atualizarTabela() {
      this.refiltrar == false
        ? (this.refiltrar = true)
        : (this.refiltrar = false);
    },

    // RELATORIOS:
    gerarContas() {
      this.$store.dispatch(START_LOADING);
      TituloService.gerarRelatorioContasPrevistas(this.filtroSelecionado)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    gerarExtrato() {
      this.$store.dispatch(START_LOADING);
      TituloService.gerarRelatorioExtrato(this.filtroSelecionado)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
