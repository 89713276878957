<template>
  <modal
    id="modalContasPagarBaixaTitulo"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <form-baixa-titulo :form="form" @refs="capturarRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="validarFormulario">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import TituloService from '@/common/services/titulo/titulo.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import FormBaixaTitulo from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormBaixaTitulo,
  },
  data() {
    return {
      refs: [],
    };
  },
  computed: {
    titulo() {
      return 'Baixar contas a pagar';
    },
  },
  methods: {
    /*
			TODO: eventualmente fazer o helpers funcionar aqui
		*/
    // FUNÇÕES AUXILIARES P/ VALIDAÇÃO DO FORMULÁRIO:
    validarFormulario() {
      var formValido = true;
      this.refs['dataPagamento'].$el.style.borderBottom = '';

      var totalParcial =
        parseFloat(this.form.valor) + parseFloat(this.form.juros);
      if (this.form.desconto > totalParcial) {
        formValido = false;
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('Existem Descontos maiores que os valores de documentos')
        );
      }

      if (this.form.dataPagamento == null) {
        formValido = false;
        this.refs['dataPagamento'].$el.style.borderBottom = '1px solid red';
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('Insira uma data de pagamento')
        );
      }

      if (formValido) {
        this.salvar();
      }
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
    // FUNÇÕES CRUD:
    salvar() {
      this.$store.dispatch(START_LOADING);
      TituloService.baixarContaPagar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'contasPagar');
    },
  },
};
</script>
