<template>
  <modal
    id="modalContasPagarAjustarTitulo"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="sm"
    @fechar="fecharModal"
  >
    <form-ajustar-titulo :form="form" @refs="capturarRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="validarFormulario">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import TituloService from '@/common/services/titulo/titulo.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import FormAjustarTitulo from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormAjustarTitulo,
  },
  data() {
    return {
      refs: [],
    };
  },
  computed: {
    titulo() {
      return this.$t('FINANCEIRO.AJUSTAR_DADOS_PAGAMENTO');
    },
  },
  methods: {
    validarFormulario() {
      var formValido = true;

      if (this.form.dataVencimento == null) {
        formValido = false;
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('FINANCEIRO.INSIRA_DATA_VENCIMENTO')
        );
      }
      if (formValido) {
        this.salvar();
      }
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
    // FUNÇÕES CRUD:
    salvar() {
      this.$store.dispatch(START_LOADING);
      TituloService.ajustarDadosContaPagar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('FINANCEIRO.DATA_VENCIMENTO_AJUSTADA'));
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
