<template>
  <div class="mt-4">
    <!-- <b-row>
      <b-col md="12" class="col-12 mb-3">
        <div>
          <b-form-checkbox v-model="selecionarTodos">
            {{ $t('GERAL.SELECIONAR_TODOS') }}
          </b-form-checkbox>

          <strong v-if="selecionarTodos">{{
            $t('GERAL.SELECIONAR_TODOS_AVISO')
          }}</strong>
        </div>
      </b-col>
    </b-row> -->
    <b-table
      class="tabela-contas-pagar"
      :items="items"
      :fields="fields"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="sm"
      show-empty
      responsive
      striped
      hover
      small
    >
      <!-- <template #cell(marcar)="item">
        <b-form-checkbox
          v-model="item.item.checked"
          :value="true"
          :unchecked-value="false"
          @change="marcaDesmarcaUm(item.item)"
        />
      </template> -->
      <!-- <template #cell(fornecedor)="item">
        <div>{{ item.item.fornecedor }} / {{ item.item.codigo }}</div>
      </template> -->
      <!-- <template #cell(dataLancamento)="item">
        <div>
          {{
            item.item.dataLancamento
              ? helpers.formatarDataBr(item.item.dataLancamento)
              : '-'
          }}
          /
          {{
            item.item.dataVencimento
              ? helpers.formatarDataBr(item.item.dataVencimento)
              : '-'
          }}
        </div>
      </template> -->
      <!-- <template #cell(numeroRecibo)="item">
        <div>
          {{
            item.item.numeroRecibo || item.item.numeroRecibo == 0
              ? item.item.numeroRecibo
              : '-'
          }}
          /
          {{ item.item.notaFiscal ? item.item.notaFiscal : '-' }}
        </div>
      </template> -->
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item
            v-if="item.item.statusPagamentoDescricao == 'Aberto'"
            @click="baixarTitulo(item.item.id)"
          >
            {{ $t('FINANCEIRO.BAIXAR_TITULO') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="item.item.statusPagamentoDescricao == 'Liquidado'"
            @click="estornarTitulo(item.item)"
          >
            {{ $t('FINANCEIRO.ESTORNAR_TITULO') }}
          </b-dropdown-item>
          <b-dropdown-item @click="confirmarExclusao(item.item)">
            {{ $t('FINANCEIRO.CANCELAR_TITULO') }}
          </b-dropdown-item>
          <b-dropdown-item @click="ajustarDadosPagamento(item.item)">
            {{ $t('FINANCEIRO.AJUSTAR_DADOS_PAGAMENTO') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(statusPagamentoDescricao)="item">
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Cancelado'"
          style="background: red"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Liquidado'"
          style="background: green"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Liquidado Parcialmente'"
          style="background: #7ccd7c"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Estornado'"
          style="background: #3a443a"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Aberto'"
          style="background: #fbcd00"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          v-if="
            item.item.statusPagamentoDescricao !== 'Aberto' &&
            item.item.statusPagamentoDescricao !== 'Liquidado' &&
            item.item.statusPagamentoDescricao !== 'Liquidado Parcialmente' &&
            item.item.statusPagamentoDescricao !== 'Estornado' &&
            item.item.statusPagamentoDescricao !== 'Cancelado'
          "
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import TituloService from '@/common/services/titulo/titulo.service';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      helpers: helpers,
      fields: [
        // {
        //   key: 'marcar',
        //   label: '',
        // },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
          sortable: true,
        },
        {
          key: 'fornecedor',
          // label: this.$t('FINANCEIRO.FORNECEDOR_DOCUMENTO'),
          label: this.$t('FINANCEIRO.FORNECEDOR'),
          sortable: true,
        },
        {
          key: 'codigo',
          label: this.$t('GERAL.DOCUMENTO'),
          sortable: true,
        },
        {
          key: 'statusPagamentoDescricao',
          label: this.$t('GERAL.STATUS'),
          sortable: true,
        },
        {
          key: 'dataLancamento',
          label: this.$t('GERAL.DATA_LANCAMENTO'),
          // label: this.$t('FINANCEIRO.LANCAMENTO_VENCIMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'dataVencimento',
          label: this.$t('GERAL.DATA_VENCIMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'dataPagamento',
          label: this.$t('GERAL.DATA_PAGAMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'historicoDescricao',
          label: this.$t('GERAL.HISTORICO'),
          sortable: true,
        },
        {
          key: 'valor',
          label: this.$t('GERAL.VALOR_BRL'),
          formatter: (value) => {
            return value ? helpers.formatacaoMoedaBRL(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'juros',
          label: this.$t('GERAL.JUROS'),
          sortable: true,
        },
        {
          key: 'desconto',
          label: this.$t('GERAL.DESCONTO'),
          sortable: true,
        },
        {
          key: 'valorEfetivo',
          label: this.$t('GERAL.VALOR_EFETIVO'),
          formatter: (value) => {
            return value ? helpers.formatacaoMoedaBRL(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'contaDescricao',
          label: this.$t('FINANCEIRO.CONTA_CAIXA'),
          sortable: true,
        },
        {
          key: 'observacoes',
          label: this.$t('GERAL.OBSERVACOES'),
          sortable: true,
        },
        {
          key: 'numeroRecibo',
          label: this.$t('FINANCEIRO.RECIBO'),
          // label: this.$t('FINANCEIRO.RECIBO_NF'),
          sortable: true,
        },
        {
          key: 'notaFiscal',
          label: this.$t('SUPRIMENTOS.NOTA_FISCAL'),
          sortable: true,
        },
      ],
      itensSelecionados: [],
      listaTemporaria: [],
      selecionarTodos: false,
    };
  },
  watch: {
    items() {
      this.itensSelecionados = [];
      // this.table.items = this.items;
    },
    selecionarTodos: 'marcarDesmarcarTodos',
    filtrarPor: 'limparItensArmazenados',
  },
  methods: {
    // limparItensArmazenados() {
    //   this.itensSelecionados = [];
    //   this.listaTemporaria = [];
    // },
    // // FUNÇÃO PARA MARCAR/DESMARCAR TODOS:
    // marcarDesmarcarTodos() {
    //   // resetando a lista:
    //   this.listaTemporaria = [];

    //   // alterando todos os checked de acordo com o dado do pai:
    //   this.items.forEach((element) => {
    //     element.checked = this.selecionarTodos;
    //     this.listaTemporaria.push(element);
    //   });

    //   // atualizando lista de itens selecionados e emitindo ao pai:
    //   this.itensSelecionados = this.selecionarTodos ? this.listaTemporaria : [];
    //   this.emitirDados();

    //   // retornando a lista temporária:
    //   return (this.items = this.listaTemporaria);
    // },
    // marcaDesmarcaUm(item) {
    //   item.checked ? this.adicionaLista(item) : this.removeLista(item);
    //   this.emitirDados();
    // },
    // adicionaLista(item) {
    //   this.listaTemporaria = this.listaTemporaria.filter(
    //     (element) => element.id != item.id
    //   );
    //   this.listaTemporaria.push(item);
    //   this.itensSelecionados = this.listaTemporaria;
    // },
    // removeLista(item) {
    //   this.listaTemporaria = this.listaTemporaria.filter(
    //     (element) => element.id !== item.id
    //   );
    //   this.itensSelecionados = this.listaTemporaria;
    // },
    // // FUNÇÃO PARA ENVIAR OS DADOS AO PAI:
    // emitirDados() {
    //   // Confere se as matrículas são iguais:
    //   var arr = this.itensSelecionados.map((item) => {
    //     return item.codigoMatricula;
    //   });
    //   const allEqual = arr.every((v) => v === arr[0]);

    //   this.$emit('itens-selecionados-tabela', this.itensSelecionados, allEqual);
    // },
    baixarTitulo(id) {
      this.$emit('baixa-titulo', id);
    },
    ajustarDadosPagamento(item) {
      this.$emit('ajustar-titulo', 'ajustarTitulo', item);
    },
    estornarTitulo(item) {
      this.$store.dispatch(START_LOADING);
      TituloService.estornarContaPagar(item.id)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Título estornado!');
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        'Confirme o cancelamento',
        'Ao cancelar uma conta, ela é excluída. Deseja realmente excluir esta conta à pagar?'
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item);
      }
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      TituloService.cancelarContaPagar(item.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            'Conta à pagar cancelada!'
          );
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.dropdown .btn-outline-default {
  min-height: 15px !important;
  line-height: 0 !important;
}
.tabela-contas-pagar {
  white-space: nowrap;
  font-size: 14px;
}

.tabela-contas-pagar .thead-light th {
  padding: 15px 0.4rem !important;
}

.tabela-contas-pagar td {
  padding: 5px 0.4rem !important;
}
</style>
