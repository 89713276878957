<template>
  <b-row>
    <b-col md="4">
      <input-text
        ref="documento"
        v-model="selecionados.codigo"
        type="text"
        label="FINANCEIRO_CONTAS_PAGAR.DOCUMENTO"
        placeholder="FINANCEIRO_CONTAS_PAGAR.DOCUMENTO_PLACEHOLDER"
        @enter="listarLancamentos"
      />
    </b-col>

    <b-col md="5" class="mb-3 mb-md-0">
      <input-select-multiple
        ref="contaId"
        v-model="selecionados.contaId"
        :label="$t('FINANCEIRO.CONTA')"
        :options="opcoes.contas"
        :loading="loadingConta"
      />
    </b-col>

    <b-col md="3" class="mb-3 mb-md-0">
      <input-select-multiple
        ref="statusPagamentoId"
        v-model="selecionados.statusPagamentoId"
        :label="$t('FINANCEIRO_CONTAS_PAGAR.STATUS')"
        placeholder="FINANCEIRO_CONTAS_PAGAR.STATUS_PLACEHOLDER"
        :options="opcoes.status"
        :loading="loadingStatus"
      />
    </b-col>

    <b-col md="6" class="mb-3 mb-md-0">
      <input-select-multiple
        ref="fornecedorId"
        v-model="selecionados.fornecedorId"
        :label="$t('Fornecedores')"
        placeholder="Fornecedores"
        :options="opcoes.fornecedores"
        :loading="loadingFornecedores"
      />
    </b-col>

    <b-col md="6" class="mb-3 mb-md-0">
      <input-select-multiple
        ref="historicoLancamentoId"
        v-model="selecionados.historicoLancamentoId"
        :label="$t('Histórico lançamento')"
        placeholder="Histórico lançamento"
        :options="opcoes.historicos"
        :loading="loadingHistorico"
      />
    </b-col>

    <data-lancamento
      v-if="exibirBuscaAvancada"
      :exibir="opcoesAvancadas.dataLancamento"
      :form="selecionados"
      class="botao-acao-filtro mt-3"
    >
      <template #checkbox>
        <input-checkbox
          v-model="opcoesAvancadas.dataLancamento"
          :label="$t('FINANCEIRO_CONTAS_PAGAR.DATA_LANCAMENTO')"
        />
      </template>
    </data-lancamento>

    <data-pagamento
      v-if="exibirBuscaAvancada"
      class="botao-acao-filtro"
      :exibir="opcoesAvancadas.dataPagamento"
      :form="selecionados"
    >
      <template #checkbox>
        <input-checkbox
          v-model="opcoesAvancadas.dataPagamento"
          :label="$t('FINANCEIRO_CONTAS_PAGAR.DATA_PAGAMENTO')"
        />
      </template>
    </data-pagamento>

    <data-vencimento
      v-if="exibirBuscaAvancada"
      class="botao-acao-filtro"
      :exibir="opcoesAvancadas.dataVencimento"
      :form="selecionados"
    >
      <template #checkbox>
        <input-checkbox
          v-model="opcoesAvancadas.dataVencimento"
          :label="$t('FINANCEIRO_CONTAS_PAGAR.DATA_VENCIMENTO')"
        />
      </template>
    </data-vencimento>

    <b-col md="auto" class="d-md-flex align-items-md-center">
      <input-checkbox
        variant="primary"
        class="botao-acao-filtro mb-md-3 w-100"
        :label="$t('GERAL.BUSCA_AVANCADA')"
        v-model="exibirBuscaAvancada"
      />
    </b-col>

    <b-col md="auto">
      <b-button
        variant="primary"
        class="botao-acao-filtro my-3 w-100"
        @click="listarLancamentos"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button
        variant="secondary"
        class="botao-acao-filtro mb-3 w-100"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>

    <!-- Botoes de relatórios -->
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click="gerarContasPrevistas"
        :disabled="disabled"
      >
        {{ $t('FINANCEIRO.GERAR_CONTAS_PREVISTAS') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click="gerarExtrato"
        :disabled="disabled"
      >
        {{ $t('FINANCEIRO_CONTAS_RECEBER.GERAR_EXTRATO') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';

// Services:
import ContaService from '@/common/services/conta/conta.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import EmpresaService from '@/common/services/empresa/empresa.service.js';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

// Components:
import {
  DataLancamento,
  DataVencimento,
  DataPagamento,
} from './opcoes-avancadas/index';
import {
  InputText,
  InputSelectMultiple,
  InputCheckbox,
} from '@/components/inputs';

export default {
  components: {
    InputCheckbox,
    DataLancamento,
    DataVencimento,
    DataPagamento,
    InputText,
    InputSelectMultiple,
  },
  props: {
    exibirBotoesRelatorios: { type: Boolean, default: true },
    disabled: { type: Boolean, default: true },
    filtroAntigo: { type: Object, default: () => {} },
  },
  data() {
    return {
      exibir: false,
      loadingFornecedores: false,
      loadingHistorico: false,
      loadingStatus: false,
      loadingConta: false,
      limpar: false,
      selecionados: {},
      opcoes: {
        contas: [],
        historicos: [],
        status: [],
        fornecedores: [],
      },
      filtroRepasse: [
        'fornecedorId',
        'codigo',
        'statusPagamentoId',
        'contaId',
        'historicoLancamentoId',
        'dataPagamentoAte',
        'dataPagamentoInicio',
        'dataLancamentoAte',
        'dataLancamentoInicio',
        'dataVencimentoAte',
        'dataVencimentoInicio',
      ],
      filtroAvancadoRepasse: [
        'dataPagamentoAte',
        'dataPagamentoInicio',
        'dataLancamentoAte',
        'dataLancamentoInicio',
        'dataVencimentoAte',
        'dataVencimentoInicio',
      ],
      opcoesAvancadas: {
        dataLancamento: false,
        dataPagamento: false,
        dataVencimento: false,
      },
      exibirBuscaAvancada: false,
    };
  },
  mounted() {
    this.getStatus();
    this.getContas();
    this.getFornecedores();
    this.getHistoricoLancamento();
  },
  watch: {
    exibirBuscaAvancada: {
      handler() {
        this.filtrosAvancados();
      },
    },
    filtroAntigo() {
      if (this.filtroAntigo?.codigo) {
        this.selecionados.codigo = this.filtroAntigo?.codigo;
      }
    },
  },
  methods: {
    validaFiltroAntigoConta() {
      if (this.filtroAntigo?.contaId) {
        this.opcoes.contas.forEach((item) => {
          if (item.value == this.filtroAntigo.contaId) {
            this.selecionados.contaId = item;
          }
        });
      }
    },
    getContas() {
      this.loadingConta = true;
      ContaService.obterTodasSemPaginacao()
        .then(({ data }) => {
          this.opcoes.contas = data;

          this.validaFiltroAntigoConta();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingConta = false;
        });
    },
    validaFiltroAntigoFornecedor() {
      if (this.filtroAntigo?.fornecedorId) {
        this.opcoes.fornecedores.forEach((item) => {
          if (item.value == this.filtroAntigo.fornecedorId) {
            this.selecionados.fornecedorId = item;
          }
        });
      }
    },
    getFornecedores() {
      this.loadingFornecedores = true;
      EmpresaService.obterTodosFornecedoresSemPaginacao()
        .then(({ data }) => {
          data = data.map((row) => ({
            text: row.razaoSocial,
            value: row.id,
          }));
          this.opcoes.fornecedores = data;

          this.validaFiltroAntigoFornecedor();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingFornecedores = false;
        });
    },
    validaFiltroAntigoHistorico() {
      if (this.filtroAntigo?.historicoLancamentoId) {
        this.opcoes.historicos.forEach((item) => {
          if (item.value == this.filtroAntigo.historicoLancamentoId) {
            this.selecionados.historicoLancamentoId = item;
          }
        });
      }
    },
    getHistoricoLancamento() {
      this.loadingHistorico = true;
      HistoricoLancamentoService.obterTodosHistoricosLancamentosSemPaginacao()
        .then(({ data }) => {
          this.opcoes.historicos = data;

          this.validaFiltroAntigoHistorico();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingHistorico = false;
        });
    },
    validaFiltroAntigoStatus() {
      if (this.filtroAntigo?.statusPagamentoId) {
        this.opcoes.status.forEach((item) => {
          if (item.value == this.filtroAntigo.statusPagamentoId) {
            this.selecionados.statusPagamentoId = item;
          }
        });
      }
    },
    getStatus() {
      this.loadingStatus = true;
      EnumeradoresService.listar('status-pagamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.status = data;

          this.validaFiltroAntigoStatus();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    // FUNÇÕES FILTRO:
    limparFiltro() {
      Object.keys(this.selecionados).forEach((item) => {
        this.selecionados[item] = '';
      });
      this.limpar == false ? (this.limpar = true) : (this.limpar = false);

      this.filtrosAvancados();
      this.$emit('limpar', []);
    },
    // FUNÇÕES DE EMISSÃO DE DADOS:
    criarObjetoParaFiltro() {
      return this.filtroRepasse.reduce((acc, string) => {
        acc[string] =
          typeof this.selecionados[string] === 'string'
            ? this.selecionados[string]
            : this.selecionados[string]?.value;
        return acc;
      }, {});
    },
    criarObjetoParaDeFiltroAvancado() {
      return this.filtroAvancadoRepasse.reduce((acc, string) => {
        acc[string] = this.selecionados[string];
        return acc;
      }, {});
    },
    listarLancamentos() {
      let filtro = this.criarObjetoParaFiltro();
      let filtroAvancado = this.criarObjetoParaDeFiltroAvancado();

      let filtros = {
        ...filtro,
        ...filtroAvancado,
      };

      this.$emit('filtrar', filtros);
    },

    filtrosAvancados() {
      this.opcoesAvancadas = {
        dataLancamento: false,
        dataPagamento: false,
        dataVencimento: false,
      };
    },

    // gerar relatórios
    gerarContasPrevistas() {
      this.$emit('gerar-contas');
    },

    gerarExtrato() {
      this.$emit('gerar-extrato');
    },
    // baixarTitulo() {
    //   this.$emit('baixar-titulo');
    // },
    // estornarTitulo() {
    //   this.$emit('estornar-titulo');
    // },
    // cancelarTitulo() {
    //   this.$emit('cancelar-titulo');
    // },
    // ajustarDadosPagamento() {
    //   this.$emit('ajustar-dados-pagamento-titulo');
    // },
  },
};
</script>
